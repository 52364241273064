<template>
    <div class="page">
    <page-section>
          <div class="pump__header" :style="`background-image: url('${require('../assets/gallery/pump-2.jpg')}')`">
            <div class="pump__header--title">
              <h1 v-html="t('title')">
              </h1>
            </div>
          </div>
          <section-title>{{t('subtitle')}}</section-title>
          <div class="pump__features">
            <pump-card v-for="i in features" :key="i.icon" :data="i"></pump-card>
          </div>
          <div class="pump__impulse">
            <div class="pump__impulse--image">
              <img src="@/assets/icons/solar-impulse.png" alt="">
            </div>
            <div class="pump__impulse--content">
              <h2>
                {{t('impulse.title')}}
              </h2>
              <p>
                {{t('impulse.text')}}
              </p>
              <a href="https://solarimpulse.com/efficient-solutions/pump-drink" target="_blank">
                <Svg icon="right-arrow" className="pump__impulse--svg"/>
                {{t('impulse.cta')}}
              </a>
            </div>
          </div>
  </page-section>
  <page-section :id="item.id" v-for="(item, j) in features" :key="`details-${item.icon}`" :color="j%2 === 0 ? 'grey' : ''">
    <pump-feature :data="item"></pump-feature>
  </page-section>
  <page-section color="grey">
          <section-title>{{t('installation.title')}}</section-title>
          <p>
            {{t('installation.text')}}
          </p>
           <picture-line :data="installationPictures"></picture-line>
  </page-section>
  <page-section>
          <section-title>{{t('download.title')}}</section-title>
          <div class="pump__download">
            <a href="/download/pad-presentation-en.pdf"  :download="t('download.documents[0]')" class="pump__download--item">
              <Svg icon="pdf" className="pump__download--svg"/>
              {{t('download.documents[0]')}}
            </a>
             <a href="/download/pad-presentation-fr.pdf"  :download="t('download.documents[1]')" class="pump__download--item">
              <Svg icon="pdf" className="pump__download--svg"/>
              {{t('download.documents[1]')}}
            </a>
            <a href="/download/pad-presentation-pt.pdf"  :download="t('download.documents[2]')" class="pump__download--item">
              <Svg icon="pdf" className="pump__download--svg"/>
              {{t('download.documents[2]')}}
            </a>
             <a href="/download/pad-presentation-sw.pdf"  :download="t('download.documents[3]')" class="pump__download--item">
              <Svg icon="pdf" className="pump__download--svg"/>
              {{t('download.documents[3]')}}
            </a>
          </div>
  </page-section>
  </div>
</template>

<script>
import HeadingCard from '@/components/HeadingCard';
import PageSection from '@/components/PageSection';
import SectionTitle from '@/components/section/SectionTitle';
import PictureLine from '@/components/PictureLine';
import PumpCard from '@/components/pump/PumpCard';
import PumpFeature from '@/components/pump/PumpFeature';

import Svg from '@/components/utils/Svg';
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';


export default {
    components:{
      HeadingCard,
      PageSection,
      PictureLine,
      PumpCard,
      PumpFeature,
      SectionTitle,
      Svg
    },
    setup(){
        const {t} = useI18n({
            messages:{
              en: require('@/locales/en/pump.json'),
              fr: require('@/locales/fr/pump.json'),
              pt: require('@/locales/pt/pump.json'),
              sw: require('@/locales/sw/pump.json')
            }
        })

      const installationPictures = [
             {
                 name:"installation/installation-1.jpg"
             },
             {
                 name:"installation/installation-2.jpg"
             },
             {
                 name:"installation/installation-3.jpg"
             },
             {
                 name:"installation/installation-4.jpg"
             },
             {
                 name:"installation/installation-8.jpeg"
             },
        ];
      const features = computed(() => [
        {
          id:"standup-water",
          title:t('features.standup.title'),
          titleDescr:t('features.standup.titleCard'),
          descr: t('features.standup.descr'),
          icon: "watercarry.svg",
          image:"standup.jpg",
          content:[
            {
              title:t('features.standup.content.gravity.title'),
              type: "text",
              text:[
                {
                  type:"p",
                  content: t('features.standup.content.gravity.text[0]'),
                }
              ]

            }
          ]
        },
        {
          id:"water-tank",
          title:t('features.tank.title'),
          titleDescr:t('features.tank.titleCard'),
          descr: t('features.tank.descr'),
          icon: "watertank.svg",
          image:"water-tank.jpg",
          content:[
            {
              title:t('features.tank.content.storage.title'),
              type: "text",
              text:[
                {
                  type:"p",
                  content: t('features.tank.content.storage.text[0]')
                },
                {
                  type:"p",
                  content: t('features.tank.content.storage.text[1]')
                },
                {
                  type:"p",
                  content: t('features.tank.content.storage.text[2]')
                }
              ]
            },
             {
              title:t('features.tank.content.cleaning.title'),
              type: "text",
              text:[
                {
                  type:"p",
                  content: t('features.tank.content.cleaning.text[0]')
                },
              ]
            }
          ]
        },

        {
          id:"water-pumping",
          title:t('features.pump.title'),
          titleDescr:t('features.pump.titleCard'),
          descr: t('features.pump.descr'),
          icon: "pumpanddrink.svg",
          image:"water-pumping.jpg",
           content:[
            {
              title:t('features.pump.content.pump.title'),
              type: "text",
              text:[
                {
                  type:"p",
                  content: t('features.pump.content.pump.text[0]')
                },
              ]
            },
             {
              title:t('features.pump.content.installation.title'),
              type: "text",
              text:[
                {
                  type:"p",
                  content: t('features.pump.content.installation.text[0]')
                },
              ]
            }
          ]
        },
        {
          id:"solar-powered",
          title:t('features.solar.title'),
          titleDescr:t('features.solar.titleCard'),
          descr: t('features.solar.descr'),
          icon: "solar.svg",
          image:"solar-power.jpg",
          content:[
            {
              title:t('features.solar.content.panel.title'),
              type: "text",
              text:[
                {
                  type:"p",
                  content: t('features.solar.content.panel.text[0]')
                },
              ]
            }
          ]
        },
        {
          id:"data-monitoring",
          title:t('features.data.title'),
          titleDescr:t('features.data.titleCard'),
          descr: t('features.data.descr'),
          icon: "data.svg",
          image:"data-monitoring.jpg",
          content:[
            {
              title:t('features.data.content.remote.title'),
              type: "text",
              text:[
                {
                  type:"p",
                  content: t('features.data.content.remote.text[0]')
                },
                 {
                  type:"p",
                  content: t('features.data.content.remote.text[1]')
                }
              ]
            },
             {
              title:t('features.data.content.maintenance.title'),
              type: "text",
              text:[
                {
                  type:"p",
                  content: t('features.data.content.maintenance.text[0]')
                },
              ]
            }
          ]
        },
        {
          id:"drinking-water",
          title:t('features.drink.title'),
          titleDescr:t('features.drink.titleCard'),
          descr: t('features.drink.descr'),
          icon: "water.svg",
          image:"filter.jpg",
          content:[
            {
              title:t('features.drink.content.drinking.title'),
              type: "text",
              text:[
                {
                  type:"p",
                  content: t('features.drink.content.drinking.text[0]')
                },
                {
                  type:"list",
                  content: [
                    t('features.drink.content.drinking.list[0]'),
                    t('features.drink.content.drinking.list[1]'),
                    t('features.drink.content.drinking.list[2]'),
                  ]
                },
                {
                  type:"p",
                  content:  t('features.drink.content.drinking.text[1]'),
                }
              ]
            },
             {
              title:t('features.drink.content.maintenance.title'),
              type: "text",
              text:[
                {
                  type:"p",
                  content: t('features.drink.content.maintenance.text[0]')
                },
              ]
            }
          ]
        },
      ]);
      return{
        features,
        installationPictures,
        t
      }
    }
}
</script>

<style lang="scss">

@import '../style/main';
.pump{
  &__header{
    height: 50rem;
    position: relative;
    
    background-position: center;
    background-size: cover;
    margin-bottom: 6rem;

    @include respond(phone){
        height: 30rem;
      }

    &--title{
      position: absolute;
      top: 5rem;
      right: 0;
      padding:  3rem 5rem;
      background-color: rgba($color-white, .8);

      @include respond(phone){
        width: 100%;
        padding: 2rem;
        // bottom: 0;
        top: 50%;

        transform: translateY(-50%);
      }

      & h1{
        text-transform: uppercase;
        letter-spacing: .2rem;
        font-size: 4rem;
        line-height: 4.8rem;
         @include respond(phone){
          text-align: center;
        }
      }

      
    }
  }

  &__features{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @include respond(phone){
      flex-direction: column;
    }
  }

  &__download{
    &--item{
      text-decoration: none;
      color:inherit;
      display: flex;
      align-items: center;
      background-color: $color-white;
      box-shadow: $shadow;
      padding: 1.2rem 2rem;
      border-radius: 5px;
      border: 1px solid currentColor;

      font-size: 1.8rem;
      text-transform: uppercase;
      font-weight: 700;

      transition: all .2s;

      &:not(:last-child){
        margin-bottom: 2rem;
      }

      &:hover{
        background-color: $color-background-grey;
        transform: scale(1.03);
      }

      @include respond(phone){
        font-size: 1.4rem;
      }
    }
    &--svg{
      color: #dd5b7d;
      @include svg(4rem);
      margin-right: 2rem;
    }
  }

  &__impulse{
    background-color: $color-white;
    border: 1px solid currentColor;
    border-radius: 6px;
    box-shadow: $shadow;
    padding: 2rem 4rem;
    display: flex;
    align-items: center;

    @include respond(phone){
      flex-direction: column;
      margin-top: 4rem;
    }

    &--image{
      flex: 0 0 12%;
      margin-right: 4rem;

      @include respond(phone){
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 0;
        padding: 2rem;
      }
      & img{
        width: 100%;
        height: auto;
        @include respond(phone){
          width: 50%;
        }
      }
    }

    &--content{

      & a{
        text-decoration: none;
        color:inherit;
        display: flex;
        align-items: center;

        transition: all .2s;

        &:hover{
          transform: translateX(1rem);
        }
      }

      & h2{
        margin-bottom: 1rem;
      }
    }

    &--svg{
      @include svg(2rem);
      margin-right: 2rem;
    }
  }
}
</style>
